export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/main-logo.png',
    banner_img_root : '/img/banner.jpg',
    resid : 'e3ba92170cc92b34858d743da2ec514f',
    key_value : 'meenas',
    secret_value : 'meenas',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12239.914239480224!2d-105.089307!3d39.919496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b8baf14d8cea1%3A0xbf879246e7327a1c!2s135%20Nickel%20St%2C%20Broomfield%2C%20CO%2080020%2C%20USA!5e0!3m2!1sen!2sin!4v1597242271941!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Meena's Restaurant"
};
